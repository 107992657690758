export default function Home() {
  return (
    <section id="home">
      <div className="container">
        <div className="content">
          <div className="text">
            <h3>
              ciliegia dischi
            </h3>
            <p>
              <a href="mailto:scrivi@ciliegia.xyz">scrivi@ciliegia.xyz</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
